<template>
  <div class='container'>
    <div class="content">
      <h2>请补充学生的家庭住址</h2>
      <div class="select">
        <input type="text" v-model="address" readonly @click="isShow=true">
        <van-icon class="arrow" name="arrow-down" size="5.3333vw"/>
      </div>
      <textarea class="area" v-model="formData.home_address" placeholder="请输入详细地址"></textarea>
    </div>
    <div class="bottom">
      <button @click="submit">保存</button>
    </div>
    <van-popup v-model="isShow" position="bottom" :style="{ height: '40%' }">
       <van-area 
         title="选择所在地区" 
         :value="currentAddres"
         :area-list="areaList"  
         @confirm="onConfirm"
         />
     </van-popup>
  </div>
</template>

<script>
import { areaList } from '@vant/area-data';
import {mapState} from 'vuex'
import {profileUpdateV2} from '@/api/student.js'
export default {
  data(){
    return{
      isShow:false,
      areaList:[],
      currentAddres:[],
      formData: {
          id: '',
          sn: '', // 学籍号
          grade: '', // 年级
          studyClass: '', // 班级
          gender: '', // 性别
          area: '', // 校区
          birthday: '', // 出生日期
          nation: '', // 民族
          birth_place: '', // 籍贯
          situation: '', // 学习情况
          interest: [], // 兴趣
          interest_other: '', // 兴趣
          home_address: '', // 家庭地址
          health_status: '', // 健康状况
          psychological_diagnsis: '', // 心理诊断记录
          past_medical_history: [], // 过往身体病史
          past_medical_history_other: '', // 过往身体病史
          parent_relationship: '', // 父母关系
          family_atmosphere: '', // 家庭气氛
          family_economy: '', // 家庭经济情况
          other: '', // 其他情况
          f_name: '', // 父亲名字
          f_age: '', // 年龄
          f_occupation: '', // 职业
          f_characteristics: '', // 个人特点
          f_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          m_name: '', // 母亲名字
          m_age: '', // 年龄
          m_occupation: '', // 职业
          m_characteristics: '', // 个人特点
          m_like_level: '', // 喜爱程度 1 喜欢 2 不喜欢
          study: [], // 学习经历
          event: [], // 重大生活事件
          home: [], // 家庭成员
          province: '', // 省
          city: '', // 市
          county: '' // 区
      },
    }
  },
  computed:{
    ...mapState(['student']),
    address(){
      return `${this.formData.province}-${this.formData.city}-${this.formData.county}`
    }
  },
  async created(){
    this.areaList=areaList
    await this.getDetail()
    
  },
  methods:{
    async getDetail(){
      const {id}=this.$route.query
      await this.$store.dispatch('getStudentDetail',id)
      this.formData=this.student
      this.currentAddres=this.getKeyByValue(this.areaList.province_list,this.formData.province)
      /* this.currentAddres=[
        this.getKeyByValue(this.areaList.province_list,this.formData.province),
        this.getKeyByValue(this.areaList.city_list,this.formData.city),
        this.getKeyByValue(this.areaList.county_list,this.formData.county)
      ] */
    },
    onConfirm(val){
      console.log(val);
      this.formData.province=val[0].name
      this.formData.city=val[1].name
      this.formData.county=val[2].name
      this.isShow=false
    },
    // 获取对应值的键名
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    async submit(){
      if(!this.formData.province&&!this.formData.city&&!this.formData.city){
        this.$toast.fail('请选择所在地区')
        return
      }
      if(!this.formData.home_address){
        this.$toast.fail('请输入详细地址')
        return
      }
      const res = await profileUpdateV2(this.formData)
      this.$toast.success('修改成功')
      this.$router.back()
    },
  }
}
</script>
<style lang='less' scoped>
.container{
  min-height: 100%;
  padding-top: 5.3333vw;
  padding-bottom: 18.6667vw;
  .content{
    padding: 0 4vw;
    .select{
      margin-top: 5.3333vw;
      position: relative;
      input{
        padding: 0 10.6667vw 0 6.1333vw;
        width: 100% ;
        height: 12.2667vw;
        border-radius: 13.3333vw;
        background: #F5F7F9;
      }
      .arrow{
        position: absolute;
        top: 50%;
        right: 4vw;
        transform: translateY(-50%);
      }
    }
    textarea{
      padding: 4vw;
      width: 100%;
      min-height: 53.3333vw;
      margin-top: 5.3333vw;
      background: #F5F7F9;
      border-radius: 1.3333vw;
      font-weight: 550;
    }
  }
  .bottom{
    position: fixed;
    display: flex;
    align-items: center;
    padding: 0 4vw;
    bottom: 0;
    width: 100%;
    height: 16vw;
    button{
      width: 100%;
      color: #fff;
      height:13.8667vw;
      line-height: 13.8667vw;
      font-size: 4vw;
      border-radius: 13.3333vw;
      background: #5EDBC9;
    }
  }
}
</style>